import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function Services() {
  return (
    <Container className="text-center py-5" style={{ minHeight: "100vh" }}>
      <Row className="align-items-center h-100">
        <Col>
          <h1 className="display-4 fw-bold">Services Page</h1>
          <p className="lead">
            We're working hard to bring this page to life. Stay tuned for amazing updates!
          </p>
         
          <Button variant="primary" onClick={() => window.history.back()}>
            Go Back
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Services;

// Login.js

import React from "react";
import "./GoogleLogin.css";

const apiUrl = process.env.REACT_APP_API_URL_BLOGURL;

const GoogleLogin = () => {
  const loginWithGoogle = () => {
    window.open(`${apiUrl}/auth/google`, "_self");
  };

  return (
    <div className="login-page">
      <div className="form">
        <button className="login-with-google-btn" onClick={loginWithGoogle}>
          Sign In With Google
        </button>
      </div>
    </div>
  );
};

export default GoogleLogin;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Banner from "./Banner";

const apiUrl = process.env.REACT_APP_API_URL_BLOGURL;

const BlogMainPage = () => {
  const [originalProductData, setOriginalProductData] = useState([]);
  const [filteredProductData, setFilteredProductData] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = () => {
    fetch(`${apiUrl}/approved-blogs`)
      .then((res) => res.json())
      .then((jsonRes) => {
        setOriginalProductData(jsonRes);
        setFilteredProductData(jsonRes);

        const uniqueCategories = [
          "All",
          ...new Set(jsonRes.map((item) => item.category)),
        ];
        setUniqueCategories(uniqueCategories);
      })
      .catch((error) => {
        console.error("Error fetching approved blogs:", error);
      });
  };

  const filterCategory = (catItem) => {
    if (selectedCategory === catItem) {
      setSelectedCategory("All");
      setFilteredProductData(originalProductData);
    } else {
      setSelectedCategory(catItem);
      if (catItem === "All") {
        setFilteredProductData(originalProductData);
      } else {
        const result = originalProductData.filter(
          (curData) => curData.category === catItem
        );
        setFilteredProductData(result);
      }
    }
    setSearchTerm("");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterByName = () => {
    const result = originalProductData.filter(
      (curData) =>
        curData.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        curData.summary.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProductData(result);
  };

  return (
    <>
      <Banner />
      <div className="container-fluid py-5">
        <div className="row">
          {/* Left Sidebar */}
          <div className="col-lg-3 col-md-4 mt-5">
            <div className="position-sticky mx-5" style={{ top: "20px" }}>
              <h4 className="mb-4 fw-bold">Explore By Categories</h4>
              <div
                className="d-flex flex-column gap-2 rounded"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                {uniqueCategories.map((category, index) => (
                  <button
                    key={index}
                    className={`btn ${
                      selectedCategory === category
                        ? "btn-primary"
                        : "btn-light"
                    } d-flex justify-content-between align-items-center text-start px-4 py-2 w-100`}
                    style={{ fontSize: "14px" }}
                    onClick={() => filterCategory(category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="col-lg-8 col-md-8">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h1 className="display-4 fw-bold">
                All Posts<span className="text-danger">.</span>
              </h1>
              <div className="d-flex align-items-center">
                <label
                  htmlFor="categorySelect"
                  className="me-2 select_Category"
                >
                  Select Category:
                </label>
                <select
                  id="categorySelect"
                  className="form-select w-auto"
                  value={selectedCategory}
                  onChange={(e) => filterCategory(e.target.value)}
                >
                  {uniqueCategories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mb-4">
              <div className="d-flex gap-3">
                <input
                  type="text"
                  className="form-control w-50"
                  placeholder="Search by title or summary"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <button className="btn btn-primary" onClick={filterByName}>
                  Search
                </button>
              </div>
            </div>

            <div className="blogwrapper row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
              {filteredProductData.length > 0 ? (
                filteredProductData.map((blog, id) => (
                  <div key={id} className="col-md-4 mb-4">
                    <div className="card border-0 shadow-sm">
                      {blog.thumbnail && (
                        <img
                          src={`${apiUrl}/${blog.thumbnail}`}
                          className="card-img-top"
                          alt="blog thumbnail"
                        />
                      )}
                      <div className="card-body">
                        <span className="badgee bg-dark mb-2">
                          {blog.category}
                        </span>

                        <span className="blogdate">
                          {moment(blog.createdAt).format("MMM D, YYYY")}
                        </span>
                        <h5 className="hovertitle">
                          <Link
                            to={`/blog/${blog.slug}`}
                            className="text-decoration-none text-dark"
                          >
                            {blog.title}
                          </Link>
                        </h5>
                        {/* <span>{blog.author?.name || "Anonymous"}</span> */}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-12">
                  <div className="alert alert-warning">No blog found</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogMainPage;

import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const url = process.env.REACT_APP_API_URL_BACKEND;

const HiringForm = () => {
  const [formData, setFormData] = useState({});
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    // Append all form fields
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    // Append file if present
    if (file) {
      formDataToSend.append("resume", file);
    }

    try {
      const response = await fetch(`${url}/hiresubmit`, {
        method: "POST",
        body: formDataToSend, // Don't set Content-Type header - browser will set it with boundary
      });

      const result = await response.json();

      if (response.ok) {
        alert("Form submitted successfully!");
        setFormData({});
        setFile(null);
      } else {
        alert(result.message || "Form submission failed. Please try again.");
        console.error("Error:", result.error || result.message);
      }
    } catch (err) {
      console.error("Error while submitting form:", err);
      alert("An error occurred while submitting the form. Please try again.");
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Hiring Form for Income Tax Filing</h2>
      <form onSubmit={handleSubmit}>
        <h4>Personal Information</h4>
        <div className="row mb-3">
          <div className="col-md-6">
            <label>
              Full Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="fullName"
              placeholder="your name"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6">
            <label>
              Email Address<span className="text-danger">*</span>
            </label>
            <input
              type="email"
              name="email"
              placeholder="example@gmail.com"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label>
              Phone Number<span className="text-danger">*</span>
            </label>
            <input
              type="tel"
              name="phone"
              placeholder="+919999999999"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6">
            <label>
              Date of Birth<span className="text-danger">*</span>
            </label>
            <input
              type="date"
              name="dob"
              placeholder="01-02-2000"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label>
              Address<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="address"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2">
            <label>City</label>
            <input
              type="text"
              name="city"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2">
            <label>State</label>
            <input
              type="text"
              name="state"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2">
            <label>ZIP Code</label>
            <input
              type="text"
              name="zip"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="mb-3 col-md-6">
            <label>
              Gender<span className="text-danger">*</span>
            </label>
            <select
              name="gender"
              className="form-control"
              onChange={handleChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="mb-3 col-md-6">
            <label>LinkedIn Profile (optional)</label>
            <input
              type="url"
              name="linkedin"
              className="form-control"
              onChange={handleChange}
            />
          </div>
        </div>
        <h4>Educational Background</h4>
        <div className="row mb-3">
          <div className="col-md-6">
            <label>Highest Qualification</label>
            <input
              type="text"
              name="qualification"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6">
            <label>University/College Name</label>
            <input
              type="text"
              name="college"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label>
              Year of Graduation<span className="text-danger">*</span>
            </label>
            <input
              type="number"
              name="graduationYear"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6">
            <label>Specialization (if any)</label>
            <input
              type="text"
              name="specialization"
              className="form-control"
              onChange={handleChange}
            />
          </div>
        </div>

        <h4>Professional Information</h4>
        <div className="mb-3">
          <label>
            Are you currently employed?<span className="text-danger">*</span>
          </label>
          <select
            name="employed"
            className="form-control"
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label>Current or Previous Job Role (if any)</label>
            <input
              type="text"
              name="jobRole"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <label>
              Years of Experience<span className="text-danger">*</span>
            </label>
            <input
              type="number"
              name="experience"
              className="form-control"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="mb-3">
          <label>Availability for Work</label>
          <select
            name="availability"
            className="form-control"
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Full-time">Full-time</option>
            <option value="Part-time">Part-time</option>
            <option value="Freelance">Freelance</option>
          </select>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label>Notice Period (if applicable)</label>
            <input
              type="text"
              name="noticePeriod"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <label>Available Start Date</label>
            <input
              type="date"
              name="startDate"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <h4>Skills & Expertise</h4>
        <div className="row mb-3">
          <div className="col-md-6">
            <label>Experience with Income Tax Filing?</label>
            <select
              name="taxExperience"
              className="form-control"
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="col-md-6">
            <label>
              Knowledge of Taxation Laws<span className="text-danger">*</span>
            </label>
            <select
              name="taxKnowledge"
              className="form-control"
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>

        <div className="mb-3">
          <label>Experience with Tax Filing Software (specify names)</label>
          <input
            type="text"
            name="taxSoftware"
            className="form-control"
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label>Typing Speed (optional)</label>
          <input
            type="text"
            name="typingSpeed"
            className="form-control"
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label>Proficiency in MS Excel or Google Sheets</label>
          <select
            name="excelProficiency"
            className="form-control"
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="mb-3">
          <label>Any Certification in Taxation</label>
          <select
            name="certification"
            className="form-control"
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <h4>Upload Section</h4>
        <div className="mb-3">
          <label>Resume or CV</label>
          <input
            type="file"
            name="resume"
            className="form-control"
            onChange={handleFileChange}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary mb-5">
          Submit
        </button>
      </form>
    </div>
  );
};

export default HiringForm;

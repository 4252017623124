import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import CommentSection from "./CommentSection";
import moment from "moment";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  RedditShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditIcon,
} from "react-share";
import Breadcrumb from "./Breadcrumb";
import Banner from "./Banner";

const apiUrl = process.env.REACT_APP_API_URL_BLOGURL;

function BlogDetails() {
  const [blog, setBlog] = useState(null);
  const { slug } = useParams();
  const [comments, setComments] = useState([]);
  const [commentsLoaded, setCommentsLoaded] = useState(false);
  const [relatedPosts, setRelatedPosts] = useState([]);

  let profilePicURL = null;

  useEffect(() => {
    axios
      .get(`${apiUrl}/searchslug/${slug}`)
      .then((response) => {
        setBlog(response.data);
        fetchRelatedPosts(response.data.tags);
      })
      .catch((error) => {
        console.error("Error fetching blog details:", error);
      });
  }, [slug]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/comments/${slug}`)
      .then((response) => {
        setComments(response.data);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  }, [slug]);

  const fetchRelatedPosts = (tags) => {
    if (tags && tags.length > 0) {
      axios
        .get(`${apiUrl}/blogs?tags=${tags.join(",")}`)
        .then((response) => {
          setRelatedPosts(response.data);
        })
        .catch((error) => {
          console.error("Error fetching related posts:", error);
        });
    }
  };

  const loadComments = () => {
    setCommentsLoaded(true);
  };

  if (!blog) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (blog.author && blog.author.profilePic) {
    profilePicURL = `${apiUrl}/${blog.author.profilePic}`;
  }

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    alert("Link copied to clipboard!");
  };

  return (
    <div className="container my-5">
      <Breadcrumb blogTitle={blog.title} />
      <Banner />

      <div className="row">
        {/* Left Column: Blog Content */}
        <div className="col-lg-8 col-md-7 col-sm-12 mb-4 mt-5 ">
          <div className="d-flex justify-content-between textcolor">
            <p className="text-muted publish_wrap">
              Published on: {new Date(blog.createdAt).toLocaleDateString()}
            </p>
            <p className="text-muted publish_wrap">Category: {blog.category}</p>
          </div>
          <h1 className="display-5 mt-4">{blog.title}</h1>

          {/* Blog Image */}
          {blog.image && (
            <img
              src={`data:image/jpeg;base64,${blog.image}`}
              alt="Blog"
              className="img-fluid mb-4 rounded"
            />
          )}
          {/* Blog Content */}
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{ __html: blog.content }}
          ></div>
          <div>
            {Array.isArray(blog.tags) && blog.tags.length > 0 && (
              <div className="mt-4">
                <h5 className="fs-3">Tags:</h5>
                <div className="d-flex flex-wrap gap-2">
                  {blog.tags.map((tag, index) => (
                    <Link
                      key={index}
                      to={`/blogs/category/${tag}`}
                      className="badge bg-primary text-decoration-none me-2"
                      style={{ marginBottom: "8px" }}
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
          
          {/* Share Options */}
          <div className="mt-5">
            <h5 className="fs-3">Share this post:</h5>
            <div className="d-flex align-items-center gap-3">
              <FacebookShareButton
                url={window.location.href}
                quote={blog.title}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={window.location.href} title={blog.title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton
                url={window.location.href}
                title={blog.title}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <RedditShareButton url={window.location.href} title={blog.title}>
                <RedditIcon size={32} round />
              </RedditShareButton>
              <button
                onClick={copyLinkToClipboard}
                className="btn btn-outline-secondary"
              >
                Copy Link
              </button>
            </div>
          </div>
        </div>

        {/* Right Column: Author Info, Share Buttons, and Comments */}
         <div className="col-lg-4 col-md-5 col-sm-12 rightWrapper">
          {/* <div className="card mb-4 mt-5">
            <div className="card-body d-flex align-items-center">
              {profilePicURL ? (
                <img
                  src={profilePicURL}
                  alt="Author Profile"
                  className="rounded-circle me-3 mb-3"
                  style={{ width: "60px", height: "60px" }}
                />
              ) : (
                <div
                  className="rounded-circle bg-secondary me-3 mb-3"
                  style={{ width: "60px", height: "60px" }}
                ></div>
              )}
              <div>
                <h6 className="mb-1">{blog.author?.name || "Anonymous"}</h6>
                <p className="text-muted mb-0">
                  {blog.author?.description || "Author description"}
                </p>
              </div>
            </div>
          </div> */}

          {/* Comments Section */}
          <div className="card text-center commentbox mt-5">
            <div className="card-body">
              <h6 className="mb-4">Comments</h6>
              {comments.map((comment) => (
                <div key={comment.id} className="mb-3">
                  <div className="d-flex align-items-start">
                    <div
                      className="rounded-circle bg-secondary me-3"
                      style={{ width: "40px", height: "40px" }}
                    ></div>
                    <div>
                      <h6 className="mb-1">{comment.user || "Anonymous"}</h6>
                      <p className="mb-0">{comment.text}</p>
                    </div>
                  </div>
                </div>
              ))}

              {!commentsLoaded && (
                <button
                  className="btn btn-primary mt-3 w-50"
                  onClick={loadComments}
                >
                  Load Comments
                </button>
              )}

              {commentsLoaded && <CommentSection blogId={slug} />}
            </div>
          </div>
        </div> 

        {/* Related Posts */}
        {relatedPosts.length > 0 && (
          <div className="mt-5">
            <h5 className="fs-3">Related Posts:</h5>
            <div className="row">
              {relatedPosts.slice(0, 3).map((post) => (
                <div key={post.id} className="col-md-4 mb-4">
                  <div className="blogwrapper card max-height: 100% border-0 shadow-sm">
                    <img
                      src={`${apiUrl}/${post.thumbnail}`}
                      className="card-img-top"
                      alt="post thumbnail"
                    />
                    <div className="card-body">
                      <span className="badgee bg-dark mb-2">
                        {post.category}
                      </span>
                      <span className="datewrapper">
                        {moment(post.createdAt).format("MMM D, YYYY")}
                      </span>
                      <h5 className="hovertitle">
                        <Link
                          to={`/relatedblog/${post.slug}`}
                          className="text-decoration-none text-dark hover"
                        >
                          {post.title}
                        </Link>
                      </h5>
                    </div>
                    <div className="card-footer border-0">
                      <div className="d-flex justify-content-between align-items-center"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BlogDetails;
